import React from 'react';
import LazyLoad from 'react-lazyload';
import IndexLayout from 'layouts';
import { Components } from 'sitedata';
import FlatRatePage from 'views/SellPage/iprice';
import { styled } from 'styles';

const FlatRate = () => (
  <IndexLayout>
    <StyledHeader hasBorder />
    <FlatRatePage />
    <LazyLoad>
      <Components.Footer />
    </LazyLoad>
  </IndexLayout>
);

export default FlatRate;

const StyledHeader = styled(Components.Header)`
  background: #ffffff;
`;
